<script>
  export let leftValue,
    rightValue,
    value,
    disabled,
    style = "";

  let checked = value === rightValue;

  $: {
    if (disabled) {
      checked = value === rightValue;
    } else {
      value = checked ? rightValue : leftValue;
    }
  }

  let leftSelected, rightSelected;
  $: leftSelected = value === leftValue;
  $: rightSelected = value === rightValue;
</script>

<div class="slider-container" {style}>
  <div class="left" class:selected={leftSelected}>
    <slot name="left">{leftValue}</slot>
  </div>
  <div class="slider">
    <input id="monthly-toggle" type="checkbox" {disabled} bind:checked />
    <label for="monthly-toggle" />
  </div>
  <div class="right" class:selected={rightSelected}>
    <slot name="right">{rightValue}</slot>
  </div>
</div>

<style>
  @import "../styles/variables.css";

  .slider-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slider-container .left,
  .slider-container .right {
    flex-basis: 50%;
    flex-shrink: 1;
  }

  .slider-container .left {
    text-align: right;
  }

  .slider {
    font-size: 1.5em;
    margin: 0 1em;
  }

  .slider input {
    display: none;
  }

  .slider label {
    width: 2em;
    height: 1em;
    position: relative;
    cursor: pointer;
    display: block;
  }

  .slider label:before {
    content: "";
    position: absolute;
    width: 2em;
    height: 1em;
    transition: background 0.1s ease;
    background: var(--primary);
    border-radius: 0.5em;
  }

  .slider label:after {
    content: "";
    position: absolute;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    left: 0;
    transition: transform 0.2s ease;
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
    background: var(--tertiary);
    z-index: var(--slider-layer);
  }

  .slider input[type="checkbox"]:checked + label:before {
    background: var(--primary-light);
  }

  .slider input[type="checkbox"]:checked + label:after {
    transform: translateX(1em);
  }

  .slider input[type="checkbox"]:disabled + label:before,
  .slider input[type="checkbox"]:checked:disabled + label:before {
    opacity: 0.5;
  }
</style>
